import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProductList = () => {
  const [products, setProducts] = useState([]); // 상품 목록
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(null); // 에러 상태
  const navigate = useNavigate(); // useNavigate 훅 선언

  // 데이터 fetch
  useEffect(() => {
    fetch(` /api/product/category/24`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ page: 0, size: 10 }), // Optional: Add pagination data if needed
    })
      .then((response) => response.json())
      .then((data) => {
        setProducts(data); // Set the returned product list directly
        setLoading(false); // 로딩 완료
      })
      .catch((error) => {
        console.error('데이터를 가져오는 중 오류 발생:', error);
        setError(error);
        setLoading(false); // 에러 발생 시 로딩 중지
      });
  }, []);

  // 로딩 중 상태 처리
  if (loading) {
    return <div>로딩 중...</div>;
  }

  // 에러 발생 시 처리
  if (error) {
    return <div>오류가 발생했습니다: {error.message}</div>;
  }

  // 상품 클릭 시 상세 페이지로 이동하는 함수
  const goToDetailPage = (productId) => {
    navigate(`/custom/persona-made/detail/${productId}`); // '/detail/상품ID'로 이동
  };

  return (
    <section className="py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h2 className="font-bold text-3xl sm:text-4xl text-black mb-8 text-center">
          Special Order
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {products.map((product) => (
            <a
              key={product.seq} // Use `seq` from the API response
              href="#!"
              onClick={() => goToDetailPage(product.seq)}
              className="max-w-[384px] mx-auto"
            >
              <div className="w-full max-w-sm aspect-square relative">
                <img
                  src={product.image300}
                  alt={product.name}
                  className="w-full h-full rounded-xl object-cover"
                />
              </div>
              <div className="mt-5 flex items-center justify-between">
                <div>
                  <h6 className="font-medium text-xl leading-8 text-black mb-2">
                    {product.name}
                  </h6>
                  <p className="text-gray-700 mb-2">{product.description}</p>
                  <h6 className="font-semibold text-xl leading-8 text-gray-600">
                    {product.price.toLocaleString()}원
                  </h6>
                </div>

              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductList;
